
import bubbleBg from "@/static/js/bubble.js";
import hash from "blueimp-md5";
import qs from "qs";
import { Component, Vue } from "vue-property-decorator";
import { Loading } from "element-ui";

interface LoginData {
  email: string;
  password: string;
  rememberme: number;
  verifycode: string;
}
@Component({
  name: "Login",
  props: {},
  components: {},
})
export default class Login extends Vue {
  [x: string]: any;
  systemInfo: any;
  zzlFormat: any;
  concactVisible: boolean = false;
  dialog: any = {
    title: "系统公告",
    visible: false,
    showFooter: false,
  };
  formData: LoginData = {
    email: "",
    password: "",
    rememberme: 0,
    verifycode: "",
  };
  formRules: any = {
    email: [{ required: true, message: "请输入账号", trigger: "blur" }],
    password: [{ required: true, message: "请输入密码", trigger: "blur" }],
    verifycode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
  };
  verifyPicSrc: string = " ";
  inquire() {
    this.$router.push({ name: "Index" }).catch(() => {});
  }
  examAction() {
    this.$router.push({ name: "ExamLogin" }).catch(() => {});
  }
  openDialog() {
    this.getAllNotice();
  }
  closeDialog() {
    this.dialog.visible = false;
  }
  async getAllNotice() {
    const res = await this.zzlApi.notice.GetAll({}, true);
    if (res.code === 0) {
      this.dialog.visible = true;
      console.log(res);
    } else {
      this.$message.error(res.msg);
    }
  }
  showConcact() {
    this.concactVisible = !this.concactVisible;
  }

  loginAction() {
    (this.$refs["form"] as HTMLFormElement).validate(async (valid: any) => {
      if (valid) {
        const params: any = { type: "I" };
        params.Name = this.formData.email.trim();
        params.Vcode = this.formData.verifycode.trim();
        if (this.systemInfo.area == "sd" && this.systemInfo.env == "live") {
          //山东线上
          params.Pwd = hash(
            this.formData.password.trim() + "_c4537e8e39064f279ba8cfcb35d25b67"
          );
        } else {
          params.Pwd = hash(
            this.formData.password.trim() + "_zzlfe25ec32c2d65"
          );
        }
        const res = await this.zzlApi.home.Login(params, true);
        if (res.code === 0) {
          window.sessionStorage.setItem("userName", this.formData.email);
          window.sessionStorage.setItem("loginStatus", "1");
          window.sessionStorage.setItem("isZJ", res.data.extensions.isZJ);
          const initCode: any = res.data.extensions.districtCode;
          window.sessionStorage.setItem(
            "exportAllow",
            JSON.stringify(res.data.extensions.exportAllow)
          );
          // 区号8637
          window.sessionStorage.setItem("initCode", initCode);
          const config = JSON.stringify(res.data.extensions);
          window.sessionStorage.setItem("systemConfig", config);
          const loginData = JSON.stringify(res.data);
          window.sessionStorage.setItem("loginData", loginData);
          window.sessionStorage.setItem("currentRoleName", "销售");
          // 登录公司的id
          window.sessionStorage.setItem("instId", res.data.extensions.instid);
          this.$router.push({ name: "Frame" }).catch(() => {});
          window.sessionStorage.setItem("currentNavTitle", "人员档案管理");
        } else {
          let errorText: string = "";
          switch (res.code) {
            case 500:
              errorText = "账号被锁定,请联系开发人员";
              this.errorTip(errorText);
              break;
            case 901:
              errorText = "验证码错误,请重新登录";
              this.errorTip(errorText);
              break;
            default:
              errorText = res.msg;
              this.errorTip(errorText);
              break;
          }
        }
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  errorTip(errorText: string) {
    this.$message.error(errorText);
    this.formData.verifycode = "";
    this.verifyPic();
  }
  verifyPic() {
    this.verifyPicSrc =
      this.zzlApi.domain() +
      "base/home/VerificationCode?height=40&width=128&fontsize=20&rnd=" +
      Math.random();
  }
  clear() {
    window.sessionStorage.clear();
  }

  async mounted() {
    bubbleBg("canvaBg");
    //限制登录、维护提醒
    this.params = qs.parse(window.location.hash.substr(20));
    if (this.params.status === "true") {
      if (this.$store.state.loading) {
        this.$store.state.loading.close();
      }
    }
    if (this.params.status === "maintain") {
      const maintenanceTime: any =
        window.localStorage.getItem("maintenanceTime");
      let times: string = JSON.parse(maintenanceTime).join("、");
      this.$message({
        type: "warning",
        message: "系统处于维护时间段（  " + times + "），请稍后登录使用。",
        duration: 5000,
      });
    }
    this.clear();
    this.verifyPic();
  }
}
